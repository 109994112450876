import 'reflect-metadata';
import { BaseApiModel } from './base-api.model';
import { RelationHandlingMetadata } from './../decorators/symbols';

/**
 * Checks for created, updated or deleted relations and sets them accordingly (Do not change the order!):
 * 1. Set plain model of existentModels (model.id === plainModel.id)
 * 2. Check for removed models (those that are not existent in existingModel[key] anymore) and mark for disassociation / destruction
 * 3. Add new plainModels (!plainModel.id or not in exisitngModel)
 * {BaseApiModel} existingModel:  The rich model
 * {BaseApiModel['plainModel']} updatedPlainModel:  The plain model
 * {string} key:  The `hasMany` key of the model
 */
export function updateHasManyRelations(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string): void {
  const relationHandlings = Reflect.getMetadata(RelationHandlingMetadata, existingModel) || [];
  const relationHandling = relationHandlings.find((rh: any) => rh.propertyName === key);

  existingModel[key].forEach((em: any) => {
    const plainRelatedModel = updatedPlainModel[key].find((upm: any) => upm.id === em.id);
    if (plainRelatedModel) {
      // 1. Set plain model of existentModels
      em.plainModel = plainRelatedModel;
    } else {
      if (relationHandling && relationHandling.method === 'destroy') {
        // 2. Mark deleted models for disassociation / destruction
        em.destroyRelation = true;
      } else {
        em.deleteRelation = true;
      }
    }
  });

  // 3. Set newly added models
  // const newRelatedModels = updatedPlainModel[key].filter(upm => !upm.id) || [];
  const newRelatedModels = updatedPlainModel[key].filter((upm: any) => {
    // TO DO: FD Keep an eye on this!
    if (!upm.id) {
      return true;
    } else {
      return existingModel[key].some((em: any) => em.id === upm.id) ? false : true;
    }
  }) || [];


  // console.log('NRM', newRelatedModels, updatedPlainModel);
  existingModel[key] = [...existingModel[key], ...newRelatedModels];
}
