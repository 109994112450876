import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ListFilter, QueryParams } from '@parashift/shared/models';

const VALUE_IS_ARRAY_EXCEPTIONS = ['global_roles', 'filter[global_roles]'];

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  getParams(listFilter: ListFilter, isFragment?: boolean, getHttpParams = false) {
    listFilter = listFilter || new ListFilter();
    let params: any = {};

    if (typeof listFilter.extra_fields === 'object' && Object.keys(listFilter.extra_fields).length > 0) {
      const filter = this.prepareArrayFields(listFilter.extra_fields);
      params.extra_fields = filter;
    }

    if (typeof listFilter.fields === 'object' && Object.keys(listFilter.fields).length > 0) {
      const filter = this.prepareArrayFields(listFilter.fields);
      params.fields = filter;
    }

    if (typeof listFilter.filter === 'object' && Object.keys(listFilter.filter).length > 0) {
      const filter = this.prepareArrayFields(listFilter.filter);

      if (Object.keys(filter).length > 0) {
        params.filter = filter;
      }
    }

    if (listFilter.include) {
      params.include = listFilter.include;
    }

    if (typeof listFilter.page === 'object' && Object.keys(listFilter.page).length > 0) {
      params.page = {
        number: (listFilter.page && listFilter.page.number) || 1,
        size: (listFilter.page && listFilter.page.size) || 20
      };
    }

    if (typeof listFilter.included_page === 'object' && Object.keys(listFilter.included_page).length > 0) {
      params.page = params.page ?? {};
      for (const key in listFilter.included_page) {
        if (Object.prototype.hasOwnProperty.call(listFilter.included_page, key)) {
          params.page[key] = {
            number: listFilter.included_page[key].number ?? 1,
            size: listFilter.included_page[key].size ?? 20
          };
        }
      }
    }

    if (typeof listFilter.sorting === 'object' && Object.keys(listFilter.sorting).length > 0) {
      let columns = (listFilter.sorting.field || '').split(',');
      if (columns.length > 1) {
        columns = columns.map(column => (listFilter.sorting?.direction || '') + column);
        params.sort = columns.join(',');
      } else {
        params.sort = (listFilter.sorting.direction || '') + (listFilter.sorting.field || '');
      }
    }

    if (typeof listFilter.stats === 'object' && Object.keys(listFilter.stats).length > 0) {
      const stats = this.prepareArrayFields(listFilter.stats);
      params.stats = stats;
    }

    if (listFilter.ocr_text_contains) {
      params.ocr_text_contains = listFilter.ocr_text_contains;
    }

    if (getHttpParams) {
      return this.serializeHttpParams(params, this.serializeQueryParams(listFilter.query_params));
    }

    params = this.serializeParams(params);

    return params ? (isFragment ? params : '?' + params) : '';
  }

  private prepareArrayFields(filter: { [attribute: string]: any; }) {
    const filters: { [attribute: string]: any; } = {};
    for (const key in filter) {
      if (Object.prototype.hasOwnProperty.call(filter, key)) {
        if (Array.isArray(filter[key]) && filter[key].length > 0 && !VALUE_IS_ARRAY_EXCEPTIONS.includes(key)) {
          filters[key] = filter[key].join(',');
        } else {
          filters[key] = filter[key];
        }

        if (filters[key] === '' || filters[key] === null) {
          delete filters[key];
        }
      }
    }

    return filters;
  }

  private serializeParams(params: { [attribute: string]: any; }, prefix?: string): string {
    const query = Object.keys(params).map(key => {
      const value = params[key];

      if (params.constructor === Array) {
        key = `${prefix}[]`;
      } else if (params.constructor === Object) {
        key = prefix ? `${prefix}[${key}]` : key;
      }

      if (typeof value === 'object' && value) {
        return this.serializeParams(value, key) as unknown as ConcatArray<never>;
      } else {
        return `${key}=${encodeURIComponent(value)}` as unknown as ConcatArray<never>;
      }
    });

    // return [].concat([], query).join('&');
    return [].concat.apply([], query).join('&');
  }

  private serializeHttpParams(obj: any, params?: HttpParams): HttpParams {
    if (!params) {
      params = new HttpParams();
    }

    for (const key in obj) {
      if (!Object.prototype.hasOwnProperty.call(obj, key) || !obj[key]) {
        continue;
      }

      const value = obj[key];

      if (Array.isArray(value)) {
        if (value.length > 0) {
          params =  VALUE_IS_ARRAY_EXCEPTIONS.includes(key) ? params.append(key, JSON.stringify(value)) : params.append(key, value.join(','));
        }
        continue;
      }

      if (typeof value === 'object') {
        for (const subKey in value) {
          if (!Object.prototype.hasOwnProperty.call(value, subKey)) { continue; }

          const elt: { [attribute: string]: any; } = {};
          const k = `${key}[${subKey}]`;
          elt[k] = value[subKey];
          params = this.serializeHttpParams(elt, params);
        }
        continue;
      }

      params = params.append(key, value);
    }

    return params;
  }

  private serializeQueryParams(query_params: QueryParams): HttpParams {
    query_params = query_params || {};
    let params = new HttpParams();
    for (const [key, value] of Object.entries(query_params)) {
      params = params.append(key, value);
    }
    return params;
  }
}
