export interface QueryParams { [key: string]: string; }
interface Filter { [key: string]: any; }

export const LIST_DEFAULT_PAGE_SIZE = 20;

export class ListFilter {
  private _extra_fields?: object;
  private _fields?: object;
  private _filter?: Filter;
  private _include?: string;
  private _page?: { number?: number; size?: number };
  private _sorting?: { direction?: '-' | ''; field?: string };
  private _stats?: { total?: string[]; stage?: string[] };
  private _ocr_text_contains?: string;
  private _query_params!: QueryParams;
  private _group_by?: string;
  private _included_page?: { [included_model: string]: { number?: number; size?: number }};

  initialFilter!: string;
  filterUntouched = true;

  constructor(
    {
      extra_fields = {},
      fields = {},
      filter = {},
      include = '',
      page = {},
      sorting = {},
      stats = {},
      ocr_text_contains = '',
      query_params = {},
      group_by = '',
      included_page = {}
    }: {
      extra_fields?: object;
      fields?: object;
      filter?: Filter;
      include?: string;
      page?: {
        number?: number;
        size?: number;
      };
      sorting?: {
        direction?: '-' | '';
        field?: string;
      };
      stats?: {
        total?: string[];
        stage?: string[];
      };
      ocr_text_contains?: string;
      query_params?: QueryParams;
      group_by?: string;
      included_page?: {
        [included_model: string]: { number?: number; size?: number }
      }
    } = {},
    listDefaults = false
  ) {
    this.extra_fields = extra_fields;
    this.fields = fields;
    this.filter = filter;
    this.include = include;
    this.sorting = (Object.keys(sorting).length > 0 && sorting) || (listDefaults ? { direction: '-', field: 'id' } : undefined);
    this.stats = Object.keys(stats).length > 0 ? stats : undefined;
    this.ocr_text_contains = ocr_text_contains;
    this.query_params = query_params;
    this.group_by = group_by;
    this.included_page = included_page;

    // keep this at last in order
    this.page = (Object.keys(page).length > 0 && page) || (listDefaults ? { number: 1, size: LIST_DEFAULT_PAGE_SIZE } : undefined);
  }

  get extra_fields() {
    return this._extra_fields;
  }

  set extra_fields(value) {
    this._extra_fields = value;
  }

  get fields() {
    return this._fields;
  }

  set fields(value) {
    this._fields = value;
  }

  get filter() {
    return this._filter;
  }

  set filter(value) {
    this._filter = value;
    this.resetPageNumber();
  }

  get include() {
    return this._include;
  }

  set include(value) {
    this._include = value;
  }

  get page() {
    return this._page;
  }

  set page(value) {
    this._page = value;
  }

  get sorting() {
    return this._sorting;
  }

  set sorting(value) {
    this._sorting = value;
  }

  get stats() {
    return this._stats;
  }

  set stats(value) {
    this._stats = value;
  }

  get ocr_text_contains() {
    return this._ocr_text_contains;
  }

  set ocr_text_contains(value) {
    this._ocr_text_contains = value;
    this.resetPageNumber();
  }

  get query_params() {
    return this._query_params;
  }

  set query_params(value) {
    this._query_params = value;
  }

  get group_by() {
    return this._group_by;
  }

  set group_by(value) {
    this._group_by = value;
  }

  get included_page() {
    return this._included_page;
  }

  set included_page(value) {
    this._included_page = value;
  }

  private resetPageNumber() {
    if (this.page && this.page.number) {
      this.page.number = 1;
    }
  }
}
