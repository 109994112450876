import 'reflect-metadata';

import { RelationHandlingMetadata } from './symbols';

export function RelationHandling(method: 'disassociate' | 'destroy' = 'disassociate') {
  return (target: any, propertyName: string) => {
    const relationHandlings = Reflect.getMetadata(RelationHandlingMetadata, target) || [];

    relationHandlings.push({
      propertyName,
      method
    });

    Reflect.defineMetadata(RelationHandlingMetadata, relationHandlings, target);
  };
}
