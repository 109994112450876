import { Injectable } from '@angular/core';

@Injectable()
export abstract class Logger {
  abstract assert(...args: any[]): void;
  abstract error(...args: any[]): void;
  abstract group(...args: any[]): void;
  abstract groupEnd(...args: any[]): void;
  abstract log(...args: any[]): void;
  abstract warn(...args: any[]): void;
}
