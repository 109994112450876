import { BaseApiModel } from './base-api.model';

// FD TBD: Observe this until we really use it. Might exist different use-cases for belongsTos...
export function updateBelongsToRelation(existingModel: BaseApiModel, updatedPlainModel: BaseApiModel['plainModel'], key: string): void {
  existingModel[key] = updatedPlainModel[key];

  // if (existingModel[key] && updatedPlainModel[key]) {
  //   if (existingModel[key].id === updatedPlainModel[key].id) {
  //     existingModel[key] = updatedPlainModel[key];
  //   } else {

  //   }
  // }

  // existingModel[key].forEach((em) => {
  //   const plainRelatedModel = updatedPlainModel[key].find(upm => upm.id === em.id);
  //   if (plainRelatedModel) {
  //     // 1. Set plain model of existentModel
  //     em.plainModel = plainRelatedModel;
  //   } else {
  //     em.deleteRelation = true;
  //   }
  // });

  // // 3. Set newly added model
  // const newRelatedModel = updatedPlainModel[key].filter(upm => !upm.id) || [];
  // existingModel[key] = [...existingModel[key], ...newRelatedModel];
}
