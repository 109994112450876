export class JsonApiQueryData<T> {
  constructor(protected data: Array<T>, protected meta?: any) {
  }

  public getModels(): T[] {
    return this.data;
  }

  public getMeta(): any {
    return this.meta;
  }

  public applyModel(model: any): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    model['animate'] = true;
    this.data.unshift(model);

    if (this.meta?.meta?.stats?.total?.count) {
      this.meta.meta.stats.total.count += 1;
    }
  }

  public replaceModel(id: string, model: any): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const index = this.data.findIndex((item: any) => item['id'] === id);

    if (index > -1) {
      this.data[index] = model;
    }
  }

  public removeModel(id: string): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const index = this.data.findIndex((item: any) => item['id'] === id);

    if (index > -1) {
      this.data.splice(index, 1);

      if (this.meta?.meta?.stats?.total?.count) {
        this.meta.meta.stats.total.count -= 1;
      }
    }
  }
}
