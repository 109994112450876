import { ServiceMetadata } from './symbols';
import 'reflect-metadata';

export interface ServiceDecoratorOptions {
  model: any;
  baseUrl?: string;
  apiVersion?: string;
  endpointUrl?: string;
  beforeEndpointUrl?: string;
  afterEndpointUrl?: string;
  postfix?: string;
}

export function ServiceDecorator(options: ServiceDecoratorOptions) {
  return (target: any) => {
    Reflect.defineMetadata(ServiceMetadata, options, target);
  };
}
