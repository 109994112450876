import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { isEqual, cloneDeep as cloneDeep$1 } from 'lodash';
import Jsona from 'jsona';
import { v4 } from 'uuid';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
class NgxAirbrushModule {}
NgxAirbrushModule.ɵfac = function NgxAirbrushModule_Factory(t) {
  return new (t || NgxAirbrushModule)();
};
NgxAirbrushModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxAirbrushModule
});
NgxAirbrushModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxAirbrushModule, [{
    type: NgModule
  }], null, null);
})();
const ServiceMetadata = Symbol('ServiceMetadata');
const ModelMetadata = Symbol('ModelMetadata');
const AttributeMetadata = Symbol('AttributeMetadata');
const BelongsToMetadata = Symbol('BelongsToMetadata');
const HasManyMetadata = Symbol('HasManyMetadata');
function ServiceDecorator(options) {
  return target => {
    Reflect.defineMetadata(ServiceMetadata, options, target);
  };
}
class TypesStore {
  constructor() {
    // tslint:disable-next-line: variable-name
    this._types = {};
  }
  static getInstance() {
    if (!TypesStore.instance) {
      TypesStore.instance = new TypesStore();
    }
    return TypesStore.instance;
  }
  static add(key, obj) {
    TypesStore.getInstance().add(key, obj);
  }
  static find(key) {
    return TypesStore.getInstance().find(key);
  }
  static all() {
    return TypesStore.getInstance().all;
  }
  add(key, obj) {
    this._types[key] = obj;
  }
  find(key) {
    return this.all[key];
  }
  get all() {
    return this._types;
  }
}
TypesStore.instance = null;
function Model(options) {
  return target => {
    Reflect.defineMetadata(ModelMetadata, options, target);
    TypesStore.add(options.type, target);
  };
}
class DateConverter {
  deserialize(value) {
    if (!value) {
      return null;
    }
    return new Date(value);
  }
  serialize(value) {
    if (!value) {
      return null;
    }
    return value.toJSON();
  }
}
function Attribute(options = {}) {
  return (target, propertyName) => {
    const converter = propType => {
      let attrConverter;
      if (options.converter) {
        attrConverter = options.converter;
      } else if (propType === Date) {
        attrConverter = new DateConverter();
      } else {
        const datatype = new propType();
        if (datatype.serialize && datatype.deserialize) {
          attrConverter = datatype;
        }
      }
      return attrConverter;
    };
    const convert = (propType, value, serialize = false) => {
      const attrConverter = converter(propType);
      if (attrConverter) {
        if (serialize) {
          return attrConverter.serialize(value);
        }
        return attrConverter.deserialize(value);
      } // else
      return value;
    };
    const areEquals = (propType, oldValue, newValue) => {
      const attrConverter = converter(propType);
      if (attrConverter) {
        if (attrConverter.areEquals) {
          return attrConverter.areEquals(oldValue, newValue);
        }
        // else
        const newVal = attrConverter.serialize(newValue);
        const oldVal = attrConverter.serialize(oldValue);
        return isEqual(newVal, oldVal);
        // return newVal === oldVal;
      } // else
      return isEqual(newValue, oldValue);
      // return oldValue === newValue;
    };
    const setupMetadata = () => {
      const attributes = Reflect.getMetadata(AttributeMetadata, target) || [];
      attributes.push({
        propertyName,
        backendName: options.backendName || propertyName,
        readonly: !!options.readonly,
        converter: options.converter
      });
      Reflect.defineMetadata(AttributeMetadata, attributes, target);
    };
    const setMetadata = (instance, oldValue, newValue) => {
      const propType = Reflect.getMetadata('design:type', target, propertyName);
      instance[AttributeMetadata] = instance[AttributeMetadata] || {};
      instance[AttributeMetadata][propertyName] = {
        newValue,
        oldValue,
        backendName: options.backendName || propertyName,
        hasDirtyAttributes: !areEquals(propType, oldValue, newValue),
        serializedValue: convert(propType, newValue, true)
      };
    };
    const getter = function () {
      return this[`_${propertyName}`];
    };
    const setter = function (newVal) {
      const propType = Reflect.getMetadata('design:type', target, propertyName);
      const serialized = convert(propType, newVal);
      let oldValue = null;
      if (this.isModelInitialization() && this.id) {
        if (Array.isArray(serialized) || typeof serialized === 'object') {
          oldValue = cloneDeep$1(serialized);
        } else {
          oldValue = serialized;
        }
        // oldValue = serialized;
      } else {
        if (this[AttributeMetadata] && this[AttributeMetadata][propertyName]) {
          oldValue = this[AttributeMetadata][propertyName].oldValue;
        }
      }
      this[`_${propertyName}`] = serialized;
      setMetadata(this, oldValue, serialized);
    };
    if (delete target[propertyName]) {
      setupMetadata();
      Object.defineProperty(target, propertyName, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}
class JsonapiConverter {
  constructor() {
    this.jsona = new Jsona();
  }
  deserialize(data) {
    return this.jsona.deserialize(data);
  }
  serialize(data, includedKeys) {
    if (!data) {
      return;
    }
    // data preparation
    this.defineTempIds(data);
    const body = this.jsona.serialize({
      stuff: data,
      includeNames: includedKeys
    });
    if (!body) {
      return;
    }
    // Fix relationships and includes
    if (!body.data.id) {
      delete body.data.id;
    }
    this.addMethodsInRelations(data, body);
    this.deleteMethodsInIncludes(body);
    this.renameTempIdKeys(body);
    return body;
  }
  defineTempIds(data) {
    this.getRelationsKeys(data).forEach(key => {
      const resources = data[key];
      this.execOnElementOrArray(resources, elt => {
        if (elt.__method === 'create') {
          elt.id = elt.id || v4();
        }
        this.defineTempIds(elt);
      });
    });
  }
  addMethodsInRelations(data, body) {
    this.updateMethods(data, body.data);
    this.getRelationsKeys(data).filter(key => data[key]).forEach(key => {
      this.execOnElementOrArray(data[key], elt => this.updateMethodsInculdes(elt, body.included));
    });
  }
  updateMethodsInculdes(data, includes) {
    const found = includes && includes.find(incl => incl.id === data.id && incl.type === data.type);
    if (!found) {
      return;
    }
    this.updateMethods(data, found);
    this.getRelationsKeys(data).filter(key => data[key]).forEach(key => {
      // FD: iterate over each element in data[key]
      // this.updateMethodsInculdes(data[key], includes);
      this.execOnElementOrArray(data[key], elt => this.updateMethodsInculdes(elt, includes));
    });
  }
  updateMethods(data, resource) {
    const relations = resource.relationships;
    if (!relations) {
      return;
    }
    this.getRelationsKeys(data).filter(key => relations[key]).forEach(key => {
      this.execOnElementOrArray(relations[key].data, elt => elt.method = data[key].__method, elt => {
        const method = data[key].find(itm => itm.id === elt.id).__method;
        elt.method = method;
      });
    });
  }
  deleteMethodsInIncludes(body) {
    const includes = body.included || [];
    includes.forEach(incl => {
      delete incl.attributes.__method;
    });
  }
  renameTempIdKeys(body) {
    const includes = body.included;
    if (!includes) {
      return;
    }
    this.setTempId(body.data, includes);
    includes.forEach(elt => {
      this.setTempId(elt, includes);
    });
  }
  setTempId(resource, includes) {
    const relations = resource.relationships;
    if (!relations) {
      return;
    }
    const keys = Object.keys(relations).filter(key => relations.hasOwnProperty(key) && relations[key]);
    keys.forEach(key => {
      this.execOnElementOrArray(relations[key].data, elt => {
        if (elt.method === 'create') {
          const included = includes.find(itm => itm.id === elt.id && itm.type === elt.type);
          included['temp-id'] = included.id;
          elt['temp-id'] = elt.id;
          delete included.id;
          delete elt.id;
        }
      });
    });
  }
  getRelationsKeys(resource) {
    return resource.relationshipNames || [];
  }
  execOnElementOrArray(elements, callback, callBackArray) {
    callBackArray = callBackArray || callback;
    if (Array.isArray(elements)) {
      elements.forEach(elt => callBackArray(elt));
    } else {
      callback(elements);
    }
  }
}
class ResourceMetadataWrapper {
  constructor(model) {
    this.model = model;
  }
  allKeys(options) {
    return [...this.attributesKeys(options), ...this.relationshipKeys(options)];
  }
  relationshipKeys(options) {
    return [...this.belongsToKeys(options), ...this.hasManyKeys(options)];
  }
  attributesKeys(options) {
    let keys = this.retrieveKeys(AttributeMetadata);
    if (options && options.defined) {
      keys = keys.filter(key => this.model[key] !== undefined);
      // FIXME: quick fix to delete null values when it's a create
      if (!this.model.id) {
        keys = keys.filter(key => this.model[key] !== null);
      }
    }
    return keys;
  }
  belongsToKeys(options) {
    return this.retrieveKeys(BelongsToMetadata, options);
  }
  hasManyKeys(options) {
    return this.retrieveKeys(HasManyMetadata, options);
  }
  allMetadata() {
    return [...this.attributesMetadata(), ...this.relationshipMetadata()];
  }
  relationshipMetadata() {
    return [...this.belongsToMetadata(), ...this.hasManyMetadata()];
  }
  attributesMetadata() {
    return this.retrieveMetadata(AttributeMetadata);
  }
  belongsToMetadata() {
    return this.retrieveMetadata(BelongsToMetadata);
  }
  hasManyMetadata() {
    return this.retrieveMetadata(HasManyMetadata);
  }
  isSidepostable(propertyName) {
    let metadata = Reflect.getMetadata(BelongsToMetadata, this.model);
    let found = metadata && metadata.find(rel => rel.propertyName === propertyName);
    if (found) {
      return found.sidepostable;
    }
    metadata = Reflect.getMetadata(HasManyMetadata, this.model);
    found = metadata && metadata.find(rel => rel.propertyName === propertyName);
    if (found) {
      return found.sidepostable;
    }
  }
  isWritable(propertyName) {
    const allMetadata = this.allMetadata();
    const found = allMetadata.find(pname => pname.propertyName === propertyName);
    if (found) {
      return !found.readonly;
    }
  }
  backendPropName(propertyName) {
    const allMetadata = this.allMetadata();
    const found = allMetadata.find(pname => pname.propertyName === propertyName);
    if (found) {
      return found.backendName;
    }
  }
  frontendPropName(propertyName) {
    const allMetadata = this.allMetadata();
    // search in backend
    let found = allMetadata.find(pname => pname.backendName === propertyName);
    if (found) {
      return found.propertyName;
    }
    // search in frontend
    found = allMetadata.find(pname => pname.propertyName === propertyName);
    if (found) {
      return propertyName;
    }
  }
  retrieveKeys(symbol, options) {
    this.model.modelSerialization = true;
    const metadata = this.retrieveMetadata(symbol);
    let keys = metadata.map(btm => btm.propertyName);
    if (options && options.defined) {
      keys = keys.filter(key => this.model[key]);
    }
    this.model.modelSerialization = false;
    return keys;
  }
  retrieveMetadata(symbol) {
    return Reflect.getMetadata(symbol, this.model) || [];
  }
  getIncludedKeys(options) {
    options = (options || {}) && Object.assign({
      forBackend: true
    }, options);
    return getIncludedKeys(this.model, this, options);
  }
}
function getIncludedKeys(model, wrapper, options) {
  const keyTranslator = options.forBackend ? 'backendPropName' : 'frontendPropName';
  let localKeys = wrapper.relationshipKeys({
    defined: true
  }).filter(key => wrapper.isSidepostable(key));
  const childKeys = [];
  localKeys.forEach(key => {
    const resource = model[key];
    let cKeys = [];
    if (Array.isArray(resource)) {
      resource.forEach(elt => {
        const wrap = new ResourceMetadataWrapper(elt);
        const keys = getIncludedKeys(elt, wrap, options);
        cKeys.push(...keys);
      });
    } else {
      const wrap = new ResourceMetadataWrapper(resource);
      const keys = getIncludedKeys(resource, wrap, options);
      cKeys.push(...keys);
    }
    cKeys = cKeys.map(ckey => `${wrapper[keyTranslator](key)}.${ckey}`);
    // remove duplicates
    cKeys = cKeys.filter((item, index) => cKeys.indexOf(item) >= index);
    childKeys.push(...cKeys);
  });
  localKeys = localKeys.map(lkey => wrapper[keyTranslator](lkey));
  return [...localKeys, ...childKeys];
}
class ResourceSerializer {
  constructor(model) {
    this.model = model;
    this.mdwrapper = new ResourceMetadataWrapper(model);
  }
  toJsonapi() {
    const result = this.serialize();
    const includeKeys = this.mdwrapper.getIncludedKeys();
    const body = new JsonapiConverter().serialize(result, includeKeys);
    return body;
  }
  serialize() {
    var _a, _b;
    const type = (_b = (_a = this.model.modelOptions) === null || _a === void 0 ? void 0 : _a.backendName) !== null && _b !== void 0 ? _b : this.model.type;
    const [attributes, belongstos, hasManys] = this.serializeAll();
    const relationshipNames = [];
    relationshipNames.push(...Object.keys(belongstos).filter(key => belongstos.hasOwnProperty(key)));
    relationshipNames.push(...Object.keys(hasManys).filter(key => hasManys.hasOwnProperty(key)));
    const result = Object.assign(Object.assign(Object.assign(Object.assign({
      id: this.model.id,
      type
    }, attributes), belongstos), hasManys), {
      relationshipNames
    });
    if (this.model.isRelation) {
      result.__method = this.model.relationMethod;
    }
    return result;
  }
  toHash() {
    const res = {
      id: this.model.id,
      type: this.model.type
    };
    this.mdwrapper.attributesKeys({
      defined: true
    }).forEach(key => res[key] = this.model[key]);
    this.mdwrapper.belongsToKeys({
      defined: true
    }).forEach(key => res[key] = this.model[key].toHash());
    this.mdwrapper.hasManyKeys({
      defined: true
    }).forEach(key => {
      this.model[key].forEach(elt => {
        res[key] = res[key] || []; // no need to init res[key] if array has no elements.
        res[key].push(elt.toHash());
      });
    });
    return res;
  }
  serializeAll() {
    const attributes = this.serializeAttributes();
    const belongstos = this.serializeBelongsTo();
    const hasManys = this.serializeHasMany();
    return [attributes, belongstos, hasManys];
  }
  serializeAttributes() {
    let keys = this.mdwrapper.attributesKeys({
      defined: true
    });
    keys = keys.filter(key => this.mdwrapper.isWritable(key));
    const attributes = this.model[AttributeMetadata];
    keys = this.model.id ? keys.filter(key => attributes[key].hasDirtyAttributes) : keys;
    const result = {};
    keys.forEach(key => {
      const beKey = this.mdwrapper.backendPropName(key);
      result[beKey] = attributes[key].serializedValue;
    });
    return result;
  }
  serializeBelongsTo() {
    let keys = this.mdwrapper.belongsToKeys({
      defined: true
    });
    keys = keys.filter(key => this.mdwrapper.isWritable(key));
    // Ignore untouched relationships
    keys = keys.filter(key => this.model[key].hasBeenTouched);
    const belongstos = {};
    keys.forEach(key => {
      const sidepostable = this.mdwrapper.isSidepostable(key);
      const beKey = this.mdwrapper.backendPropName(key);
      belongstos[beKey] = sidepostable ? this.model[key].serialize() : this.model[key].sideload();
      if (!belongstos[beKey]) {
        delete belongstos[beKey];
      }
    });
    return belongstos;
  }
  serializeHasMany() {
    let keys = this.mdwrapper.hasManyKeys({
      defined: true
    });
    keys = keys.filter(key => this.mdwrapper.isWritable(key));
    const hasManys = {};
    keys.forEach(key => {
      const sidepostable = this.mdwrapper.isSidepostable(key);
      const beKey = this.mdwrapper.backendPropName(key);
      hasManys[beKey] = sidepostable ? this.model[key].filter(elt => elt.hasBeenTouched).map(elt => elt.serialize()) : this.model[key].filter(elt => elt.hasBeenTouched).map(elt => elt.sideload());
      if (hasManys[beKey].length === 0) {
        delete hasManys[beKey];
      }
    });
    return hasManys;
  }
}
class ResourceBase {
  constructor(data) {
    this.modelInitialization = false;
    this.modelSerialization = false;
    this.__isRelation = false;
    if (!data) {
      return;
    }
    this.modelInitialization = true;
    this.id = data.id;
    this.assign(data);
    this.modelInitialization = false;
  }
  get __wrapper() {
    if (!this.__mdwrapper) {
      this.__mdwrapper = new ResourceMetadataWrapper(this);
    }
    return this.__mdwrapper;
  }
  get __serializer() {
    if (!this.__resSerializer) {
      this.__resSerializer = new ResourceSerializer(this);
    }
    return this.__resSerializer;
  }
  static getModel(constructor, data) {
    const ret = new JsonapiConverter().deserialize(data);
    return new constructor(ret);
  }
  static getAsRelationship(constructor, data, options) {
    if (!data) {
      return null;
    }
    options = Object.assign({
      method: 'untouched',
      modelInit: false
    }, options);
    let model;
    if (options.modelInit) {
      model = new constructor(data);
    } else {
      model = new constructor();
      model.id = data.id;
      model.assign(data);
    }
    model.__isRelation = true;
    model.__method = options.method;
    return model;
  }
  isModelInitialization() {
    return this.modelInitialization;
  }
  get modelOptions() {
    return Reflect.getMetadata(ModelMetadata, this.constructor);
  }
  get type() {
    return this.modelOptions.type;
  }
  get hasBeenTouched() {
    const touched = this.__isRelation && this.__method !== 'untouched';
    return this.hasDirtyAttributes || this.hasDirtyBelongsTos || this.hasDirtyHasManys || touched;
  }
  get hasDirtyAttributes() {
    const attributesMetadata = this[AttributeMetadata];
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName) && attributesMetadata[propertyName].hasDirtyAttributes) {
        return true;
      }
    }
    return false;
  }
  get hasDirtyBelongsTos() {
    const keys = this.__wrapper.belongsToKeys({
      defined: true
    });
    let touched = false;
    keys.forEach(key => {
      touched = touched || this[key].hasBeenTouched;
    });
    return touched;
  }
  get hasDirtyHasManys() {
    const keys = this.__wrapper.hasManyKeys({
      defined: true
    });
    let touched = false;
    keys.forEach(key => {
      touched = touched || this[key].map(elt => elt.hasBeenTouched).reduce((res, bool) => res || bool, false);
    });
    return touched;
  }
  get isRelation() {
    return this.__isRelation;
  }
  get relationMethod() {
    if (this.__method !== 'untouched') {
      return this.__method;
    }
    if (!this.hasBeenTouched) {
      return 'untouched';
    }
    if (this.id) {
      return 'update';
    }
    return 'create';
  }
  rollbackAttributes() {
    const attributesMetadata = this[AttributeMetadata];
    for (const propertyName in attributesMetadata) {
      if (attributesMetadata.hasOwnProperty(propertyName) && attributesMetadata[propertyName].hasDirtyAttributes) {
        this[propertyName] = attributesMetadata[propertyName].oldValue;
      }
    }
  }
  assign(data) {
    const clone = this.translateKeys(data);
    Object.assign(this, clone);
    if (this.__isRelation) {
      this.__method = this.relationMethod;
    }
  }
  disassociate() {
    this.__method = 'disassociate';
  }
  destroy() {
    if (this.isRelation) {
      this.__method = 'destroy';
    } else {
      throw new Error('Destroying resource is not supported! Use the service instead.');
    }
  }
  toJsonapi() {
    return this.__serializer.toJsonapi();
  }
  toHash() {
    return this.__serializer.toHash();
  }
  sideload() {
    const method = this.relationMethod;
    if (method === 'create' || method === 'untouched') {
      return;
    }
    const type = this.modelOptions.type;
    return {
      id: this.id,
      type,
      __method: method
    };
  }
  serialize() {
    return this.__serializer.serialize();
  }
  translateKeys(obj) {
    if (obj.toHash) {
      obj = obj.toHash();
    }
    const filtred = {};
    for (const oldkey in obj) {
      if (obj.hasOwnProperty(oldkey)) {
        const newkey = this.__wrapper.frontendPropName(oldkey.toString());
        if (newkey) {
          filtred[newkey] = obj[oldkey];
        }
      }
    }
    return filtred;
  }
}
function BelongsTo(options = {}) {
  return (target, propertyName) => {
    const setupMetadata = () => {
      const belongsTos = Reflect.getMetadata(BelongsToMetadata, target) || [];
      belongsTos.push({
        propertyName,
        backendName: options.backendName || propertyName,
        readonly: !!options.readonly,
        sidepostable: !!options.sidepostable
      });
      Reflect.defineMetadata(BelongsToMetadata, belongsTos, target);
    };
    const getClass = fromVal => {
      // const klass = options.class();
      if (!Array.isArray(options.class)) {
        return TypesStore.find(options.class);
      }
      const klasses = options.class.map(klass => TypesStore.find(klass));
      if (!fromVal) {
        return klasses[0];
      }
      const type = fromVal.type;
      if (!type) {
        throw new Error('Object should be a subclass of ResourceBase or should have a type attribute!');
      }
      const prop = klasses.find(k => {
        const obj = new k();
        return obj.type === type;
      });
      if (!prop) {
        throw new Error('Object should be a subclass of ResourceBase or should have a type attribute!');
      }
      return prop;
    };
    const updateSideload = (propClass, oldValue, newValue) => {
      const noOldId = !(oldValue && oldValue.id);
      const noNewId = !(newValue && newValue.id);
      const noIds = noOldId && noNewId;
      const eqIds = noOldId || noNewId ? false : oldValue.id === newValue.id;
      // no ids: can't create a resource. This is a sideload!
      if (noIds) {
        return null;
      }
      // new don't exist: disassociate old from the resource
      if (noNewId || newValue.deleteRelation) {
        oldValue.disassociate();
        return oldValue;
      }
      // same ids: can't update a resource. This is a sideload!
      if (eqIds) {
        const type1 = oldValue.type;
        const type2 = newValue.type;
        if (!type2 || type1 === type2) {
          return oldValue;
        }
      }
      // no old or different IDs: create new relationship
      return ResourceBase.getAsRelationship(propClass, newValue, {
        method: 'update'
      });
    };
    const updateSidepost = (propClass, oldValue, newValue) => {
      const oldHaveId = !!(oldValue && oldValue.id);
      const newHaveId = !!(newValue && newValue.id);
      const newHaveVal = !!(newValue && Object.keys(newValue).filter(key => key !== 'id' && key !== 'type').length > 0);
      const eqIds = oldHaveId && newHaveId ? oldValue.id === newValue.id : false;
      // equal ids: update old
      if (eqIds) {
        if (newValue.deleteRelation) {
          oldValue.disassociate();
        }
        if (newValue.destroyRelation) {
          oldValue.destroy();
        }
        oldValue.assign(newValue);
        return oldValue;
      }
      // new have an id: change old by new
      if (newHaveId) {
        return ResourceBase.getAsRelationship(propClass, newValue, {
          method: 'update'
        });
      }
      // new don't have id but have value: create new
      if (newHaveVal) {
        return ResourceBase.getAsRelationship(propClass, newValue, {
          method: 'create'
        });
      }
      // new empty and old null: return null;
      if (!oldValue) {
        return null;
      }
      // new empty: disassociate old from the resource
      oldValue.disassociate();
      return oldValue;
    };
    const getter = function () {
      if (!this[`_${propertyName}`] && !this.modelSerialization) {
        const propClass = getClass();
        this[`_${propertyName}`] = ResourceBase.getAsRelationship(propClass, {});
      }
      return this[`_${propertyName}`];
    };
    const setter = function (newVal) {
      const propClass = getClass(newVal);
      if (this.isModelInitialization()) {
        this[`_${propertyName}`] = ResourceBase.getAsRelationship(propClass, newVal, {
          modelInit: true
        });
      } else {
        const oldVal = this[`_${propertyName}`];
        this[`_${propertyName}`] = options.sidepostable ? updateSidepost(propClass, oldVal, newVal) : updateSideload(propClass, oldVal, newVal);
      }
    };
    if (delete target[propertyName]) {
      setupMetadata();
      Object.defineProperty(target, propertyName, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}
function HasMany(options = {}) {
  return (target, propertyName) => {
    const setupMetadata = () => {
      const hasManys = Reflect.getMetadata(HasManyMetadata, target) || [];
      hasManys.push({
        propertyName,
        backendName: options.backendName || propertyName,
        readonly: !!options.readonly,
        sidepostable: !!options.sidepostable
      });
      Reflect.defineMetadata(HasManyMetadata, hasManys, target);
    };
    const updateSideload = (propClass, oldValues, newValues) => {
      if (!newValues) {
        return oldValues;
      }
      const notConcerned = oldValues && oldValues.filter(oldVal => newValues.findIndex(newVal => oldVal.id === newVal.id) === -1);
      const result = notConcerned || [];
      newValues.forEach(newVal => {
        // no ids: can't create a resource. This is a sideload!
        if (!newVal.id) {
          return;
        }
        const found = oldValues && oldValues.find(oldVal => oldVal.id === newVal.id);
        // no old or id not found: create new relationship
        if (!found) {
          result.push(ResourceBase.getAsRelationship(propClass, newVal, {
            method: 'update'
          }));
          return;
        }
        // mark for disassociation
        if (newVal.deleteRelation) {
          found.disassociate();
        }
        // keep existing relation
        result.push(found);
      });
      return result;
    };
    const updateSidepost = (propClass, oldValues, newValues) => {
      if (!newValues) {
        return oldValues;
      }
      const notConcerned = oldValues && oldValues.filter(oldVal => newValues.findIndex(newVal => oldVal.id === newVal.id) === -1);
      const result = notConcerned || [];
      newValues.forEach(newVal => {
        const newHaveId = !!(newVal && newVal.id);
        const newHaveVal = !!(newVal && Object.keys(newVal).filter(key => key !== 'id' && key !== 'type').length > 0);
        // new don't have id and value: do nothing!
        if (!newHaveId && !newHaveVal) {
          return;
        }
        // new don't have id but have value: create new
        if (!newHaveId) {
          result.push(ResourceBase.getAsRelationship(propClass, newVal, {
            method: 'create'
          }));
          return;
        }
        const found = oldValues && oldValues.find(oldVal => oldVal.id === newVal.id);
        // no old or id not found: create new relationship
        if (!found) {
          result.push(ResourceBase.getAsRelationship(propClass, newVal, {
            method: 'update'
          }));
          return;
        }
        // mark for disassociation
        if (newVal.deleteRelation || newVal.destroyRelation) {
          newVal.destroyRelation ? found.destroy() : found.disassociate();
          result.push(found);
          return;
        }
        // keep existing relation
        found.assign(newVal);
        result.push(found);
      });
      return result;
    };
    const getter = function () {
      if (!this[`_${propertyName}`] && !this.modelSerialization) {
        this[`_${propertyName}`] = [];
      }
      return this[`_${propertyName}`];
    };
    const setter = function (newVal) {
      // const propClass = options.class();
      const propClass = TypesStore.find(options.class);
      if (this.isModelInitialization()) {
        this[`_${propertyName}`] = newVal.map(elt => ResourceBase.getAsRelationship(propClass, elt, {
          modelInit: true
        }));
      } else {
        const oldVal = this[`_${propertyName}`];
        this[`_${propertyName}`] = options.sidepostable ? updateSidepost(propClass, oldVal, newVal) : updateSideload(propClass, oldVal, newVal);
      }
    };
    if (delete target[propertyName]) {
      setupMetadata();
      Object.defineProperty(target, propertyName, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true
      });
    }
  };
}
class Scope {
  constructor(service) {
    /* tslint:disable:variable-name */
    this._pagination = {};
    this._filter = {};
    this._sort = {};
    this._fields = {};
    this._include = [];
    this._stats = {};
    this.service = service;
  }
  /* tslint:enable:variable-name */
  static clone(old) {
    const clone = new Scope(old.service);
    const clonedAttr = cloneDeep(old, 'service');
    clone._pagination = clonedAttr._pagination;
    clone._filter = clonedAttr._filter;
    clone._sort = clonedAttr._sort;
    clone._fields = clonedAttr._fields;
    clone._include = clonedAttr._include;
    clone._stats = clonedAttr._stats;
    return clone;
  }
  all(options) {
    const params = this.buildParams();
    options = options || {};
    return this.service.all(Object.assign({
      params
    }, options));
  }
  find(id) {
    const params = this.buildParams();
    return this.service.find(id, params);
  }
  page(pageNumber) {
    const clone = Scope.clone(this);
    clone._pagination.number = pageNumber;
    return clone;
  }
  per(size) {
    const clone = Scope.clone(this);
    clone._pagination.size = size;
    return clone;
  }
  where(clause) {
    const clone = Scope.clone(this);
    for (const key in clause) {
      if (clause.hasOwnProperty(key)) {
        clone._filter[key] = clause[key];
      }
    }
    return clone;
  }
  stats(clause) {
    const clone = Scope.clone(this);
    for (const key in clause) {
      if (clause.hasOwnProperty(key)) {
        clone._stats[key] = clause[key];
      }
    }
    return clone;
  }
  order(clause) {
    const clone = Scope.clone(this);
    if (typeof clause === 'string') {
      clone._sort[clause] = 'asc';
      return clone;
    } // else
    for (const key in clause) {
      if (clause.hasOwnProperty(key)) {
        clone._sort[key] = clause[key];
      }
    }
    return clone;
  }
  select(clause) {
    const clone = Scope.clone(this);
    if (Array.isArray(clause) || typeof clause === 'string') {
      const type = clone.service.getModelConfig().type;
      clone._fields[type] = clause;
      return clone;
    } // else
    for (const key in clause) {
      if (clause.hasOwnProperty(key)) {
        clone._fields[key] = clause[key];
      }
    }
    return clone;
  }
  includes(clause) {
    const clone = Scope.clone(this);
    if (Array.isArray(clause)) {
      clone._include.push(...clause);
    } else {
      clone._include.push(clause);
    }
    return clone;
  }
  buildParams() {
    const params = {
      page: this._pagination,
      filter: this._filter,
      sort: this.stringifySorts(this._sort),
      fields: this._fields,
      stats: this._stats,
      include: this.stringifyIncludes(this._include)
    };
    return this.serializeParams(params);
  }
  stringifySorts(sorts) {
    if (!sorts || Object.keys(sorts).length === 0) {
      return [];
    }
    const params = [];
    for (let key in sorts) {
      if (sorts.hasOwnProperty(key)) {
        if (sorts[key] !== 'asc') {
          key = `-${key}`;
        }
        params.push(key);
      }
    }
    return params;
  }
  stringifyIncludes(includes) {
    if (!includes || Object.keys(includes).length === 0 && includes.length === 0) {
      return [];
    }
    const params = [];
    if (Array.isArray(includes)) {
      const array = includes.map(elt => this.stringifyIncludes(elt)).reduce((res, elt1) => {
        res.push(...elt1);
        return res;
      }, []);
      params.push(...array);
      return params;
    } // else
    if (typeof includes === 'object') {
      for (const key in includes) {
        if (includes.hasOwnProperty(key)) {
          const array = this.stringifyIncludes(includes[key]).map(incl => `${key}.${incl}`);
          params.push(...array);
        }
      }
      return params;
    } // else (is a string)
    params.push(includes);
    return params;
  }
  serializeParams(obj, params) {
    if (!params) {
      params = new HttpParams();
    }
    for (const key in obj) {
      if (!obj.hasOwnProperty(key) || !obj[key]) {
        continue;
      }
      const value = obj[key];
      if (Array.isArray(value)) {
        if (value.length > 0) {
          params = params.append(key, value.join(','));
        }
        continue;
      }
      // else
      if (typeof value === 'object') {
        for (const subKey in value) {
          if (!value.hasOwnProperty(subKey)) {
            continue;
          }
          const elt = {};
          const k = `${key}[${subKey}]`;
          elt[k] = value[subKey];
          params = this.serializeParams(elt, params);
        }
        continue;
      }
      // else
      params = params.append(key, value);
    }
    return params;
  }
}
function cloneDeep(obj, omit) {
  omit = omit || [];
  omit = typeof omit === 'string' ? [omit] : omit;
  // Handle simple types
  if (!obj || typeof obj !== 'object') {
    return obj;
  }
  // Handle Date
  if (obj instanceof Date) {
    const copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }
  // Handle Array
  if (obj instanceof Array) {
    const copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = cloneDeep(obj[i]);
    }
    return copy;
  }
  // Handle Object
  if (obj instanceof Object) {
    const copy = {};
    Object.keys(obj).filter(key => !omit.includes(key)).forEach(key => {
      copy[key] = cloneDeep(obj[key]);
    });
    return copy;
  }
  throw new Error('Unable to copy obj! Its type isn\'t supported.');
}
class JsonapiBaseService {
  constructor(http) {
    this.http = http;
  }
  all(options) {
    const url = this.buildUrl();
    options = options || {};
    const params = options.params;
    const request = this.http.get(url, {
      params,
      observe: 'response'
    }).pipe(map(res => this.successful(res)));
    if (options.metadata) {
      return request.pipe(map(res => ({
        data: this.extract(res),
        meta: this.extractMeta(res)
      })), catchError(res => this.handleError(res)));
    } // else
    return request.pipe(map(res => this.extract(res)), catchError(res => this.handleError(res)));
  }
  find(id, params) {
    const url = this.buildUrl(id);
    return this.http.get(url, {
      params,
      observe: 'response'
    }).pipe(map(res => this.successful(res)), map(res => this.extract(res)), catchError(res => this.handleError(res)));
  }
  save(model) {
    const url = this.buildUrl(model.id);
    const body = model.toJsonapi();
    let httpCall;
    if (model.id) {
      httpCall = this.http.put(url, body, {
        observe: 'response'
      });
    } else {
      httpCall = this.http.post(url, body, {
        observe: 'response'
      });
    }
    return httpCall.pipe(map(res => this.successful(res)), map(res => this.extract(res)), catchError(res => this.handleError(res)));
  }
  delete(id) {
    const url = this.buildUrl(id);
    return this.http.delete(url, {
      observe: 'response'
    }).pipe(map(res => this.successful(res)), catchError(res => this.handleError(res)));
  }
  extractMeta(response) {
    const body = response.body;
    const meta = body.meta;
    return meta;
  }
  extract(response) {
    const body = response.body;
    const records = body.data;
    if (Array.isArray(records)) {
      const models = [];
      records.forEach(data => {
        const model = this.deserializeModel({
          data,
          included: body.included
        });
        models.push(model);
      });
      return models;
    } else {
      const model = this.deserializeModel(body);
      return model;
    }
  }
  deserializeModel(data) {
    const modelType = this.getModelType();
    return ResourceBase.getModel(modelType, data);
  }
  handleError(error) {
    return throwError(error);
  }
  buildUrl(id) {
    const serviceConfig = this.getServiceConfig();
    const modelConfig = this.getModelConfig();
    const baseUrl = this.baseUrl;
    const apiVersion = serviceConfig.apiVersion;
    const endpointUrl = serviceConfig.endpointUrl || modelConfig.type;
    const url = [baseUrl, apiVersion, endpointUrl, id].filter(itm => !!itm).join('/');
    return url;
  }
  getModelType() {
    const serviceConfig = this.getServiceConfig();
    return serviceConfig.model();
  }
  getModelConfig() {
    return Reflect.getMetadata(ModelMetadata, this.getModelType());
  }
  getServiceConfig() {
    return Reflect.getMetadata(ServiceMetadata, this.className);
  }
  successful(response) {
    const code = response.status;
    if (code < 400) {
      return response;
    }
    throw response;
  }
  page(pageNumber) {
    const scope = new Scope(this);
    return scope.page(pageNumber);
  }
  per(size) {
    const scope = new Scope(this);
    return scope.per(size);
  }
  where(clause) {
    const scope = new Scope(this);
    return scope.where(clause);
  }
  stats(clause) {
    const scope = new Scope(this);
    return scope.stats(clause);
  }
  order(clause) {
    const scope = new Scope(this);
    return scope.order(clause);
  }
  select(clause) {
    const scope = new Scope(this);
    return scope.select(clause);
  }
  includes(clause) {
    const scope = new Scope(this);
    return scope.includes(clause);
  }
}

/*
 * Public API Surface of ngx-airbrush
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Attribute, BelongsTo, HasMany, JsonapiBaseService, Model, NgxAirbrushModule, ResourceBase, Scope, ServiceDecorator };
