"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SwitchCaseJsonMapper = exports.SwitchCaseModelMapper = exports.JsonPropertiesMapper = exports.ModelPropertiesMapper = exports.JsonDeserializer = exports.ModelsSerializer = exports.Jsona = void 0;
var Jsona_1 = require("./Jsona");
exports.Jsona = Jsona_1.default;
var ModelsSerializer_1 = require("./builders/ModelsSerializer");
exports.ModelsSerializer = ModelsSerializer_1.default;
var JsonDeserializer_1 = require("./builders/JsonDeserializer");
exports.JsonDeserializer = JsonDeserializer_1.default;
var simplePropertyMappers_1 = require("./simplePropertyMappers");
Object.defineProperty(exports, "ModelPropertiesMapper", { enumerable: true, get: function () { return simplePropertyMappers_1.ModelPropertiesMapper; } });
Object.defineProperty(exports, "JsonPropertiesMapper", { enumerable: true, get: function () { return simplePropertyMappers_1.JsonPropertiesMapper; } });
var switchCasePropertyMappers_1 = require("./switchCasePropertyMappers");
Object.defineProperty(exports, "SwitchCaseModelMapper", { enumerable: true, get: function () { return switchCasePropertyMappers_1.SwitchCaseModelMapper; } });
Object.defineProperty(exports, "SwitchCaseJsonMapper", { enumerable: true, get: function () { return switchCasePropertyMappers_1.SwitchCaseJsonMapper; } });
exports.default = Jsona_1.default;
