import { HttpErrorResponse } from '@angular/common/http';

export interface JsonApiError {
  code: string;
  status: string;
  title: string;
  detail: string;
  meta?: any;
}

export class ErrorResponse {
  errors?: JsonApiError[] = [];
  url?: string | null;
  status?: number;
  message?: string;
  name = 'ErrorResponse';

  constructor(error?: HttpErrorResponse) {
    this.errors = error?.error?.errors;
    this.url = error?.url;
    this.status = error?.status;
    this.message = error?.message;
  }
}
