import { ResourceBase } from '@parashift/ngx-airbrush';
import { updateModel } from '@parashift/shared/utils';
import { updateHasManyRelations } from './update-has-many-relations';
import { updateBelongsToRelation } from './update-belongs-to-relation';

export abstract class BaseApiModel<TPlainModel = { [attr: string]: any; }> extends ResourceBase {
  customUpdates: any = {};

  get plainModel(): TPlainModel {
    return this.toHash() as TPlainModel;
  }

  set plainModel(model) {
    this.setPlainModel(model);
  }

  protected setPlainModel(model: TPlainModel): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const hasManyKeys = this['__wrapper'].hasManyKeys({ defined: true });
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const belongsToKeys = this['__wrapper'].belongsToKeys({ defined: true });

    hasManyKeys.forEach((key: any) => this.customUpdates[key] = updateHasManyRelations);
    belongsToKeys.forEach((key: any) => this.customUpdates[key] = updateBelongsToRelation);

    updateModel(this, model, this.customUpdates);
  }

  getDirtyAttributesKeys(): string[] {
    const hash = this.toHash() as TPlainModel;
    this.rollbackAttributes();
    const previousHash = this.toHash();

    this.plainModel = hash;

    const diff: { [attributeName: string]: any; } = {};

    for (const [key, value] of Object.entries(hash)) {
      const previousValue = previousHash[key];
      if (this.isNullOrUndefined(previousValue) && value === '') { // don't consider transition (null | undefined) -> '' as dirty
        continue;
      }
      if (previousValue === value) {
        continue;
      }
      diff[key] = value;
    }

    return Object.keys(diff);
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }
}
