// recursively looks for property descriptor in prototype chain
export function getPropertyDescriptor(obj: object, key: string): PropertyDescriptor {
  if (obj === undefined || obj === null) {
    return undefined;
  }
  if (key in obj) {
    return getRecursivePropertyDescriptor(obj, key);
  }
  return undefined;
}

function getRecursivePropertyDescriptor(object: object, key: string): PropertyDescriptor {
  return Object.prototype.hasOwnProperty.call(object, key)
    ? Object.getOwnPropertyDescriptor(object, key)
    : getRecursivePropertyDescriptor(Object.getPrototypeOf(object), key);
}
