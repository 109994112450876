import { getPropertyDescriptor } from './get-property-descriptor';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Dictionary<T = any> {
  [key: string]: T;
}

type CustomUpdates<T extends Dictionary> = Partial<Record<keyof T['plainModel'], (existingModel: T, updatedPlainModel: T['plainModel'], key: string) => void>>;

export function updateModel<T extends Dictionary>(existingModel: T, updatedPlainModel: T['plainModel'], customUpdates: CustomUpdates<T> = {}): void {
  if (typeof updatedPlainModel !== 'object') {
    return;
  }

  for (const key in updatedPlainModel) {
    if (canSetModelField(existingModel, updatedPlainModel, key)) {
      const customUpdate = customUpdates[key];
      if (customUpdate) {
        customUpdate(existingModel, updatedPlainModel, key);
      } else {
        existingModel[key] = updatedPlainModel[key];
      }
    }
  }
}

function canSetModelField<T extends Dictionary>(existingModel: T, updatedPlainModel: T['plainModel'], key: string): boolean {
  if (!Object.prototype.hasOwnProperty.call(updatedPlainModel, key)) {
    return false;
  }

  const descriptor = getPropertyDescriptor(existingModel, key);
  if (descriptor && !('value' in descriptor) && descriptor.set === undefined) {
    // read only property
    return false;
  }

  return (updatedPlainModel[key] || updatedPlainModel[key] !== undefined);
}
